import * as React from 'react';
import { Layout } from '../components/layout';
import { Seo } from '../components/seo';
import * as Styles from '../styles/pages/_artist.module.scss';
import { BreadCrumb } from '../components/breadcrumb';
import { BackGround } from '../components/background';
import Search from '/static/search.svg';
import {Link, useIntl} from "gatsby-plugin-react-intl"
import { contentPathHelper } from '../utils/contentPathHelper';
import { graphql } from 'gatsby';
import InfiniteScroll  from "react-infinite-scroller"

const DISPLAY_LIMIT = 8

export default function Artist({ pageContext, data, location }) {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})

  const { nodes } = pageContext;
  const [ page, setPage ] = React.useState(0);
  const [ isPageLimit, setIsPageLimit ] = React.useState(true);
  const result = React.useMemo(() => {
    return nodes.slice(0, DISPLAY_LIMIT * (page + 1))
  }, [page]);

  const updatedArtists = React.useMemo(() => {
    return data.allMicrocmsArtist.nodes
  }, [data.allMicrocmsArtist])

  // inputへ入力された値を入れる
  const [filterQuery, setFilterQuery] = React.useState('');

  // filterQuery.nameを元にresultをフィルタリングする。
  const filteredNodes = React.useMemo(() => {
    const findTask = result;
    const filterTitle = filterQuery.name && filterQuery.name.toString().toLowerCase();

    return findTask.filter((row) => {
      if (filterQuery.name && String(row.name).toLowerCase().indexOf(filterTitle) === -1) {
        return false;
      }
      return true;
    });
  }, [filterQuery, result]);

  const handleFilter = (e) => {
    const { name, value } = e.target;
    setFilterQuery({ ...filterQuery, [name]: value });
  };

  const loadMore = React.useCallback((page) => {
    if (nodes.length <= DISPLAY_LIMIT * (page + 1)) {
      setIsPageLimit(false)
    }
    setPage(page)
  }, [nodes])

  // アーティスト一覧のcomponent
  const artistList = (list) => (
    <ul className={Styles.artist_list}>
      {list.map((node) => (
        <li key={node.artistId}>
          <Link to={`/artist/${contentPathHelper(node.artistId)}/`}>
            {(node.image_url || node.image) && (
              <div>
                <img src={node.image_url || node.image.url + '?w=200&q=80&fm=webp'} alt={`${node.name}${t('artist.illustration')}`} />
              </div>
            )}
            <p>{node.name}</p>
          </Link>
        </li>
      ))}
    </ul>
  );

  return (
    <Layout location={location}>
      <Seo title={t('artist.seo.title')} description={t('artist.seo.description')} />
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
        ]}
        currentPage={t('menu.artist')}
      />
      <BackGround>
        <section className={Styles.artist}>
          <div className={Styles.artist__wrap}>
            <h1>{t('menu.artist')}</h1>
            <div className={Styles.artist__input_wrap}>
              <img src={Search} className="search" alt="search" />
              <input type="text" name="name" placeholder={t('artist.searchArtist')} value={filterQuery.name || ''} onChange={handleFilter} />
            </div>

            {filterQuery.name ? (
              <>
                <h2>Artists</h2>
                  {filteredNodes.length ? (
                    artistList(filteredNodes)
                  ) : (
                    <p>{t('artist.notfound')}</p>
                  )}
              </>
            ) : (
              <>
                <section className={Styles.artist__new}>
                  <h2>{t('artist.profileUpdate')}</h2>
                  {updatedArtists.length > 0  ? (
                    artistList(updatedArtists)
                  ) : (
                    <p>{t('artist.notfound')}</p>
                  )}
                </section>
                <section>
                  <h2>Artists</h2>
                  {result.length > 0  ? (
                    <InfiniteScroll
                      loadMore={loadMore}
                      hasMore={isPageLimit}
                    >
                      {artistList(result)}
                    </InfiniteScroll>
                  ) : (
                    <p>{t('artist.notfound')}</p>
                  )}
                </section>
              </>
            )}
          </div>
        </section>
      </BackGround>
    </Layout>
  );
}

export const query = graphql`
  query UpdatedArtists {
    allMicrocmsArtist(limit: 4, sort: {fields: updatedAt, order: DESC}, filter: {is_private: {eq: false}}) {
      nodes {
        artistId
        name
        image_url
        image {
          url
        }
        publishedAt
        updatedAt
      }
    }
  }
`
